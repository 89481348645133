import React from "react";
import { graphql } from "gatsby";
import Layout from "components/site/Layout";
import SEO from "@w3f/gatsby-theme-w3f/src/components/site/SEO";
import { useTranslation } from "@w3f/gatsby-theme-w3f/src/utils/i18n";
import Container from "layouts/Container";
import Cta from "components/Cta";

import "./404.scss";

const NotFoundPage = () => {
  const { t } = useTranslation();

  return (
    <Layout className="not-found-page">
      <SEO title={t("404.title")} description={t("404.message")} />

      <Container className="not-found-page__message container--v-margin">
        <h1 className="t-title-1">{t("404.title")}</h1>
        <p>{t("404.message")}</p>
      </Container>
      <Container className="not-found-page__cta container--v-margin">
        <Cta
          link={{
            to: "/about/",
            title: "Learn more about Web3 Foundation",
          }}
        />
      </Container>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    ...Locales
  }
`;
